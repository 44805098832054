<template>
  <!-- table -->
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded="sm"
    style="min-height: 200px;"
  >
    <vue-good-table
      v-if="emails.length > 0"
      mode="remote"
      :columns="columns"
      :rows="emails"
      :pagination-options="{
        enabled: true,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: ID -->
        <span
          v-if="props.column.field === '_id'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row._id }}</span>
        </span>

        <!-- Column: From -->
        <span v-else-if="props.column.field === 'from'">
          <b-badge :variant="statusVariant(props.row.from)">
            {{ props.row.from }}
          </b-badge>
        </span>

        <!-- Column: To -->
        <span v-else-if="props.column.field === 'to'">
          <b-badge :variant="statusVariant(props.row.to)">
            {{ props.row.to }}
          </b-badge>
        </span>

        <!-- Column: Attachments -->
        <span v-else-if="props.column.field === 'attachments'">
          <feather-icon
            v-if="props.row.attachments"
            class="text-primary"
            icon="PaperclipIcon"
            size="20"
          />

          <feather-icon
            v-else
            class="text-danger"
            icon="XIcon"
            size="20"
          />
        </span>

        <!-- Column: CreatedAt -->
        <span v-else-if="props.column.field === 'createdAt'">
          <span class="text-nowrap">{{ new Date(props.row.createdAt).toUTCString() }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'actions'">
          <span class="d-flex justify-content-around">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              @click="viewItem(props.row._id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle"
              @click="editItem(props.row._id)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon rounded-circle"
              @click="confirmText(props.row)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <!-- pagination -->
          <div>
            <b-pagination
              :value="currentPage"
              :total-rows="rows.totalDocs"
              :per-page="rows.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>

<script>
import {
  BBadge, BPagination, BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    page: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
          sortable: false,
        },
        {
          field: 'from',
          label: 'From',
          sortable: false,
        },
        {
          field: 'to',
          label: 'To',
          sortable: false,
        },
        {
          field: 'attachments',
          label: 'Attachments',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'createdAt',
          label: 'CreatedAt',
          sortable: false,
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
        },
      ],
      rows: [],
      loading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'trips@blissfulgreece.com' : 'light-primary',
        'info@blissfulgreece.com' : 'light-primary',
        'info@touristhopper.com' : 'light-warning',
        'trips@touristhopper.com' : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    emails() {
      return this.rows.emails ? this.rows.emails : []
    },
    currentPage() {
      return this.page ? parseInt(this.page, 10) : 1
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.page': async function (page) {
      await this.loadData()
    },
  },
  async beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchEmails', {
        page: this.currentPage,
      })
        .then(() => {
          self.rows = self.$store.state.app.emails
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    handleChangePage(page) {
      this.$router.push({ name: 'emails', params: { page: `${page}` } })
    },
    editItem(item) {
      this.$router.push({ name: 'editEmail', params: { emailId: item } })
    },
    viewItem(item) {
      this.$router.push({ name: 'viewEmail', params: { emailId: item } })
    },
    confirmText(item) {
      const self = this

      this.$swal({
        title: 'Delete email ?',
        text: `You are going to delete the email with ID: ${item._id}`, /* eslint no-underscore-dangle: 0 */
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await self.$store.dispatch('app/deleteEmail', item)
            .then(() => {
              self.loadData()
              self.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Email has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.vgt-table {
  width: 100%;
}

td.vgt-left-align {
  border: 1px solid #3b4253!important;
  padding: 12px;
}
</style>
